/** @format */

import Base from '@common/application/styles/themes/Base';
import eProps from '@common/application/types/EmotionStyledProps';
import styled from '@emotion/styled';
import chroma from 'chroma-js';
import { cssVars } from '../../GlobalStyles';

//<editor-fold desc="styled Component">
interface StyledButtonProps {
  warning?: boolean;
  error?: boolean;
  disabled?: boolean;
  active?: boolean;
  primary?: boolean;
  hasDecorator?: boolean;
  busy?: boolean;
}

type esButtonProps = StyledButtonProps & eProps;

const _style = {
  backgroundColor: Base.color?.background?.primary ?? cssVars.white,
  borderColor: Base.color?.border?.primary ?? cssVars.lightGrey,
  textHoverColor: Base.color?.pseudo?.hover ?? cssVars.blue,
};

const d = (val: string) => chroma(val).darken(0.2).hex();

//<editor-fold desc="Style Getters">
const getBorderColor = (props: esButtonProps) => {
  if (props.active) {
    return props?.theme?.color?.pseudo?.hover ?? _style.textHoverColor;
  }
  if (props.error) {
    return props?.theme?.color?.border?.error ?? Base.color?.border?.error ?? cssVars.red;
  }
  if (props.warning) {
    return props?.theme?.color?.border?.warning ?? Base.color?.border?.warning ?? cssVars.yellow;
  }

  return props?.theme?.color?.border?.primary ?? _style.borderColor;
};

const getBackgroundColor = (props: esButtonProps) => {
  if (props.error) {
    return props?.theme?.color?.background?.error ?? Base.color?.background?.error ?? cssVars.red;
  }
  if (props.warning) {
    return props?.theme?.color?.background?.warning ?? Base.color?.background?.warning ?? cssVars.yellow;
  }
  if (props.primary) {
    return cssVars.blue;
  }
  return props?.theme?.color?.background?.primary ?? _style.backgroundColor;
};

const getColor = (props: esButtonProps) => {
  if (props.active) {
    return props?.theme?.color?.pseudo?.hover ?? _style.textHoverColor;
  }
  if (props.disabled) {
    return getBorderColor(props);
  }
  if (props.warning) {
    return props?.theme?.color?.text?.light ?? Base.color?.text?.light ?? cssVars.yellow;
  }
  if (props.error) {
    return cssVars.white;
  }
  if (props.primary) {
    return cssVars.white;
  }
  return props?.theme?.color?.text?.light ?? Base.color?.text?.primary ?? cssVars.white;
};

const getHoverBorderColor = (props: esButtonProps) => {
  if (props.disabled) {
    return getBorderColor(props);
  }
  if (props.warning) {
    return props?.theme?.color?.text?.light ?? Base.color?.text?.light ?? cssVars.white;
  }
  if (props.primary) {
    return cssVars.darkGrey;
  }
  if (props.error) {
    return cssVars.darkGrey;
  }
  return props?.theme?.color?.pseudo?.hover ?? _style.textHoverColor;
};

const getHoverColor = (props: esButtonProps) => {
  if (props.disabled) {
    return getColor(props);
  }
  if (props.warning) {
    return props?.theme?.color?.text?.light ?? Base.color?.text?.light ?? cssVars.white;
  }
  if (props.primary) {
    return cssVars.white;
  }
  if (props.error) {
    return cssVars.white;
  }
  return props?.theme?.color?.pseudo?.hover ?? _style.textHoverColor;
};

const getActiveBorderColor = (props: esButtonProps) => {
  if (props.disabled) {
    return getBorderColor(props);
  }
  if (props.warning) {
    return d(props?.theme?.color?.text?.light ?? Base.color?.text?.light ?? cssVars.white);
  }
  if (props.primary) {
    return cssVars.darkGrey;
  }
  if (props.error) {
    return cssVars.darkGrey;
  }
  return d(props?.theme?.color?.pseudo?.hover ?? _style.textHoverColor);
};

const getActiveColor = (props: esButtonProps) => {
  if (props.disabled) {
    return getColor(props);
  }
  if (props.warning) {
    return d(props?.theme?.color?.text?.light ?? Base.color?.text?.light ?? cssVars.white);
  }
  if (props.primary) {
    return cssVars.white;
  }
  if (props.error) {
    return cssVars.white;
  }
  return d(props?.theme?.color?.pseudo?.hover ?? _style.textHoverColor);
};
//</editor-fold>

const styles = {
  borderColor: getBorderColor,
  backgroundColor: getBackgroundColor,
  color: getColor,
  hover: {
    borderColor: getHoverBorderColor,
    color: getHoverColor,
  },
  active: {
    borderColor: getActiveBorderColor,
    color: getActiveColor,
  },
};

export const StyledButton = styled.button<StyledButtonProps>`
  border: 1px solid ${styles.borderColor};
  border-radius: 4px;
  ${({ hasDecorator }) => (hasDecorator ? 'border-bottom-left-radius: 0; border-top-left-radius: 0;' : '')}
  padding: 8px 16px;
  background-color: ${(props) => (props.disabled ? '#fcfcfc' : styles.backgroundColor)};
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 34px;
  max-height: 100%;
  cursor: ${(props) => (props.disabled || props.busy ? 'not-allowed' : 'pointer')};
  color: ${styles.color};
  transition: transform 0.1s ease;
  min-width: 70px;
  user-select: none;
  position: relative;
  //text-align: center;
  > * {
    margin-right: 0.3rem;
  }

  > *:last-child {
    margin-right: 0;
  }

  :hover {
    border-color: ${styles.hover.borderColor};
    color: ${styles.hover.color};
  }

  :active {
    border-color: ${styles.active.borderColor};
    color: ${styles.active.color};
    transform: scale(0.95);
  }
`;

StyledButton.defaultProps = {
  warning: false,
};
//</editor-fold>
