/** @format */

import { cssVars } from '@atoms/GlobalStyles';
import { StyledInput } from '@atoms/Input/styled';
import { PrefixedInput } from '@atoms/PrefixedInput';
import { Label } from '@atoms/Select';
import type { ComboBoxProps } from '@react-types/combobox';
import { Item, useComboBoxState } from 'react-stately';
import { useComboBox, VisuallyHidden } from 'react-aria';
import styled from '@emotion/styled';
import Popover from '@atoms/Popover';
import ListBox from '@atoms/Select/ListBox';
import { Key, useLayoutEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIntl } from 'react-intl';
import { AutocompleteOption, AutocompleteProps } from '@atoms/AutocompleteWithModal';

export { Item, Section } from 'react-stately';

const Wrapper = styled.div``;

const StyledClearButton = styled.span`
  position: absolute;
  top: 1px;
  bottom: 1px;
  right: 1px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${cssVars.white};
  cursor: pointer;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: ${cssVars.black};
`;

export function ComboBox<T extends object>(props: ComboBoxProps<T>) {
  const state = useComboBoxState({ ...props });

  const intl = useIntl();

  const buttonRef = useRef(null);
  const inputRef = useRef(null);
  const listBoxRef = useRef(null);
  const popoverRef = useRef(null);

  const {
    buttonProps: triggerProps,
    inputProps,
    listBoxProps,
    labelProps,
  } = useComboBox(
    {
      ...props,
      inputRef,
      buttonRef,
      listBoxRef,
      popoverRef,
      shouldFocusWrap: true,
    },
    state,
  );

  const deleteValue = (e) => {
    e.stopPropagation();
    e.preventDefault();
    props.onInputChange('');
    props.onSelectionChange(null);
  };

  return (
    <Wrapper>
      <VisuallyHidden>
        <Label {...labelProps}>{props.label}</Label>
      </VisuallyHidden>
      <PrefixedInput icon={<FontAwesomeIcon icon={['far', 'search']} />}>
        <StyledInput
          {...inputProps}
          arial-label={props.label}
          ref={inputRef}
          value={props.inputValue}
          onChange={(e) => {
            props.onInputChange(e.target.value);
          }}
          placeholder={intl.formatMessage({ defaultMessage: 'Start typing to search...' })}
        />
        {props.selectedKey && (
          <StyledClearButton onClick={deleteValue}>
            <FontAwesomeIcon icon={['fas', 'multiply']} />
          </StyledClearButton>
        )}
      </PrefixedInput>
      {state.isOpen && (
        <Popover popoverRef={popoverRef} triggerRef={inputRef} state={state} placement="bottom start" minWidth={inputRef.current.clientWidth}>
          <ListBox {...listBoxProps} listBoxRef={listBoxRef} state={state} />
        </Popover>
      )}
    </Wrapper>
  );
}

export function Autocomplete<T>(props: AutocompleteProps<T>) {
  // This is possibly unnecessary but using it to ensure that there are no duplicated keys across the application as may be the case if we just use the ID.
  // Also, there is no guarantee that values will be unique so using a composite should do the trick
  const generateKey = (option: AutocompleteOption<any>) => {
    if (!option || !option.id || !option.value) return null;
    return `${option.id} ${option.value}`;
  };

  const onSelectKey = (key: Key) => {
    const selectedOption = props.options.find((option) => generateKey(option) === key) ?? { id: null, value: '', render: () => <span></span> };
    props.onSelected(selectedOption);
    props.onChange(selectedOption?.value ?? '');
  };

  useLayoutEffect(() => {
    if (props.selected) {
      // if (props.selected && !props.value.length) {
      console.log(props.selected);
      props.onChange(props.selected.value);
    }
  }, [props.selected]);

  return (
    <ComboBox
      label={props.label}
      items={props.options}
      selectedKey={generateKey(props.selected)}
      inputValue={props.value}
      onSelectionChange={onSelectKey}
      onInputChange={(value) => {
        props.onChange(value);
      }}
    >
      {(item) => (
        <Item key={generateKey(item)} textValue={item.value}>
          <item.render />
        </Item>
      )}
    </ComboBox>
  );
}
