/** @format */

import { styles } from '@common/application/styles/global';
import styled from '@emotion/styled';
import { cssVars } from '../../GlobalStyles';

interface StyledInputProps {
  disabled?: boolean;
  hasDecorator?: boolean;
  hasPostfixDecorator?: boolean;
}

export const StyledInput = styled.input<StyledInputProps>`
  padding: 4px 8px;
  border: 1px solid ${styles.borderColor};
  border-radius: 4px;
  ${({ hasDecorator }) => (hasDecorator ? 'border-bottom-left-radius: 0; border-top-left-radius: 0; border-left: none;' : '')}
  ${({ hasPostfixDecorator }) => (hasPostfixDecorator ? 'border-bottom-right-radius: 0; border-top-right-radius: 0;' : '')}
  //padding: 0.4rem;
  background-color: ${({ disabled }) => (disabled ? '#fcfcfc' : styles.backgroundColor)};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'text')};
  height: 32px;
  max-height: 100%;
  //cursor: text;
  color: ${({ disabled }) => (disabled ? cssVars.silverGrey : styles.color)};

  > * {
    margin-right: 0.3rem;
  }

  > *:last-child {
    margin-right: 0;
  }

  :hover {
    border-color: ${({ disabled }) => (disabled ? styles.borderColor : styles.hover.borderColor)};
  }

  :focus {
    border-color: ${({ disabled }) => (disabled ? styles.borderColor : styles.active.borderColor)};
    outline: none;
  }
`;
